import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

    constructor(private authFireBase: AngularFireAuth) {}

    public anonymousSignIn(): Promise<firebase.auth.UserCredential> {
      return this.authFireBase.signInAnonymously()
    }

    public getUserIdToken(): any {
      return this.authFireBase.idToken;
    }

    public getUser(): any {
      return this.authFireBase.user;
    }

    public logout(): void {
      this.authFireBase.signOut();
    }
}
