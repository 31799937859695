import { Component } from '@angular/core';
import { AuthService } from './auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  user: any = null;

  constructor(private authService: AuthService) {
    this.authService.anonymousSignIn();
  }
  login() {
    this.authService.anonymousSignIn();
  }
  logout() {
    this.authService.logout();
  }
  getUser() {
    this.user = this.authService.getUser();
    return this.user
  }
}
