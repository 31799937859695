import { Component } from '@angular/core';
import { RestaurantWrapper } from '../restaurant';
import { RestaurantService } from '../restaurant.service';
import { faHeart, faTimes, faStar, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-swiper',
  templateUrl: './swiper.component.html',
  styleUrls: ['./swiper.component.css']
})
export class SwiperComponent {
  cards: RestaurantWrapper[] = [];
  page: number = 0;
  faHeart = faHeart;
  faTimes = faTimes;
  faStar = faStar;
  faMapMarkerAlt = faMapMarkerAlt;
  constructor(private restaurantService: RestaurantService) {
    this.loadCards()
  }

  loadCards() {
    navigator.geolocation.getCurrentPosition((position) => {
      const lat = position.coords.latitude
      const lon = position.coords.longitude

      this.restaurantService.loadCards(lat, lon, this.page).subscribe(data => {

        // console.log(data)
        data.restaurants.forEach(r => {
          this.cards.push(r)
        })
      })
    })
  }

  public reset(): void {
    this.page = 0;
    this.loadCards();
  }

  public removeBy(index: number): void {
    this.cards.splice(index, 1);
    if (this.cards.length < 5) {
      this.page += 1;
      this.loadCards();
    }
  }
}
