import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Restaurant, ReturnValue } from './restaurant';

interface Query {
  lat: number,
  lon: number,
  limit: number
}

@Injectable({
  providedIn: 'root'
})
export class RestaurantService {
  url = environment.apiBaseUrl + 'recommendations2'

  constructor(private http: HttpClient) { }

  loadCards(lat: number, lon: number, page: number): Observable<ReturnValue> {
    let params = new HttpParams()
      .set('lat', lat.toString())
      .set('lon', lon.toString())
      .set('page', page.toString());

    return this.http.get<ReturnValue>(this.url, {
      params
    })
  }
}
