<sw-card-wrapper #cardWrapper class='center'>
  <div *ngFor="let card of cards, let index = index" swSwipeable [width]="360" [height]="630" [orientation]="'x'"
    (swiped)="removeBy(index)">

    <img src={{card.restaurant.image_url}} class="no-event" />
    <div class='overlay'>
      <h3>{{card.restaurant.name}}</h3>
      <div class="chips">
        <div class='rating'>
          <img class="star" *ngFor="let i of [].constructor(card.restaurant.rating)"
            src="assets/icons/rating-whole.svg" />
          <div class="star" *ngFor="let i of [].constructor(5 - card.restaurant.rating)">
            <img *ngIf="card.restaurant.rating < 5" src="assets/icons/rating-none.svg" />
          </div>
        </div>
        <div class='price'>
          <p>{{card.restaurant.price}}</p>
        </div>
        <div class='distance'>
          <p>
            <fa-icon [icon]="faMapMarkerAlt" size="sm"></fa-icon>
            {{card.distance}}
          </p>
        </div>
        <div class='categories' *ngFor="let c of card.restaurant.categories">
          <p>
            {{c.title}}
          </p>
        </div>
      </div>
      <!-- <div class="chips">
      </div> -->

    </div>
  </div>
  <div class="buttons">
    <div class="button" (click)="cardWrapper.dislike()">
      <img src="assets/icons/close.svg" class="button" />
    </div>
    <div class="button" (click)="cardWrapper.like()">
      <img src="assets/icons/heart.svg" class="button" />
    </div>
    <!-- <button (click)="cardWrapper.dislike()">
      <fa-icon [icon]="faTimes" size="lg"></fa-icon>
    </button>
    <button (click)="cardWrapper.like()">
      <fa-icon [icon]="faHeart" [styles]="{'color': 'red'}" size="lg"></fa-icon>
    </button> -->
  </div>
</sw-card-wrapper>